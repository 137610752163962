<template>
  <div class="dt-block">
  
    <div class="dt-header">
      <div class="dt-header-main">
        <el-button
          icon="el-icon-back"
          circle
          size="small"
          @click="$router.go(-1)"
        >
        </el-button>
        <span style="margin-left: 20px;">{{ form.name }}</span>
      </div>
      <div>
        <el-button
          icon="el-icon-refresh"
          circle
          @click="refresh"
        >
        </el-button>
      </div>
    </div>
  
    <div class="dt-data">

      <div class="dt-p">
        
        <div class="dt-form">
          
          <el-form
            ref="form"
            :model="form"
            label-width="180px"
            v-loading="loading"
          >
            
            <el-form-item label="ID">
              <el-input v-model="form.id" disabled style="width: 140px;"></el-input>
            </el-form-item>

            <el-form-item label="Название">
              <el-input v-model="form.name" style="max-width: 400px;"></el-input>
            </el-form-item>
            
            <el-form-item
              label="Менеджер"
            >
              <el-select
                v-model="form.manager_id"
                filterable
              >
                <el-option
                  v-for="m in options.managers"
                  :key="m.value"
                  :label="m.label"
                  :value="m.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item
              label="Второй менеджер"
            >
              <el-select
                v-model="form.manager_p_id"
                filterable
              >
                <el-option
                  v-for="m in options.managers"
                  :key="m.value"
                  :label="m.label"
                  :value="m.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="ID (Трейд)">
              <el-input v-model="form.trade_su_user_id" style="width: 140px;"></el-input>
            </el-form-item>
            
            <el-form-item>
              <el-button type="primary" @click="updateCard">Изменить</el-button>
            </el-form-item>

            <el-form-item label="Комментарий">
              <el-input
                style="max-width: 400px;"
                type="textarea"
                :rows="3"
                placeholder="Комментарий"
                v-model="form.comment"
              >
              </el-input>
              <div
                v-if="form.comment_author || form.comment_time_update"
                class="comment-info"
              >
                <span>Обновил:</span>{{ form.comment_author + ' ' + '( ' + form.comment_time_update + ' )' }}
              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="updateComment">Обновить комментарий</el-button>
            </el-form-item>

          </el-form>

        </div>
        
        <div class="dt-info">
          
          <el-card
            class="dt-registration-card"
            shadow="hover"
            v-if="form.registration_info"
          >
            <div slot="header" shadow="hover">
              <span>Регистрационная информация</span>
            </div>
            <div
              v-for="f in registration_card"
              :key="f.name"
              class="dt-field"
            >
              <div class="dt-label">{{ f.label }}:</div>
              <div class="dt-value">{{ form.registration_info[f.name] }}</div>
            </div>
          </el-card>

        </div>
        
      </div>

      <div class="dt-subheader">
        <span>Статистика</span>
      </div>
      
      <div class="dt-twrap" style="background-color: #ffffff; padding: 20px 0px;">
        
        <el-row :gutter="20">
          <el-col :span="12">
            
            <el-collapse>
              <el-collapse-item>
                <template slot="title">
                  <div class="stats_header">
                    <span class="stats_title">Работа с приложением</span>
                    <span class="stats_amount" v-if="stats_headers.app.amount"><span class="stats_last">{{ stats_headers.app.last }}</span>({{ stats_headers.app.amount }})</span>
                  </div>
                </template>
                <el-table
                  :data="stats.app"
                  :show-header="false"
                  size="mini"
                  style="margin-left: 20px;"
                >
                  <el-table-column
                    prop="label"
                    width="220"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="time_event"
                    width="150"
                  >
                    <template slot-scope="scope">
                      <i class="el-icon-time" style="color: rgb(144, 147, 153);"></i>
                      <span style="margin-left: 10px">{{ scope.row.time_event }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
            
          </el-col>
          <el-col :span="12">
            
            <el-collapse>
              <el-collapse-item>
                <template slot="title">
                  <div class="stats_header">
                    <span class="stats_title">Тендеры в работу</span>
                    <span class="stats_amount" v-if="stats_headers.tender_add_to_towork.amount"><span class="stats_last">{{ stats_headers.tender_add_to_towork.last }}</span>( {{ stats_headers.tender_add_to_towork.amount }} )</span>
                  </div>
                </template>
                <el-table
                  :data="stats.tender_add_to_towork"
                  :show-header="false"
                  size="mini"
                  style="margin-left: 20px;"
                >
                  <el-table-column
                    prop="label"
                    width="220"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="time_event"
                    width="150"
                  >
                    <template slot-scope="scope">
                      <i class="el-icon-time" style="color: rgb(144, 147, 153);"></i>
                      <span style="margin-left: 10px">{{ scope.row.time_event }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="120"
                  >
                    <template slot-scope="scope">
                      <el-button
                        @click.native.prevent="gotoTender(scope.row.target_id)"
                        type="text"
                        size="small"
                      >
                        №{{ scope.row.target_id }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <div class="stats_header">
                    <span class="stats_title">Тендеры в избранное</span>
                    <span class="stats_amount" v-if="stats_headers.tender_add_to_favorites.amount"><span class="stats_last">{{ stats_headers.tender_add_to_favorites.last }}</span>( {{ stats_headers.tender_add_to_favorites.amount }} )</span>
                  </div>
                </template>
                <el-table
                  :data="stats.tender_add_to_favorites"
                  :show-header="false"
                  size="mini"
                  style="margin-left: 20px;"
                >
                  <el-table-column
                    prop="label"
                    width="220"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="time_event"
                    width="150"
                  >
                    <template slot-scope="scope">
                      <i class="el-icon-time" style="color: rgb(144, 147, 153);"></i>
                      <span style="margin-left: 10px">{{ scope.row.time_event }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="120"
                  >
                    <template slot-scope="scope">
                      <el-button
                        @click.native.prevent="gotoTender(scope.row.target_id)"
                        type="text"
                        size="small"
                      >
                        №{{ scope.row.target_id }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <div class="stats_header">
                    <span class="stats_title">Ссылки на ЭТП</span>
                    <span class="stats_amount" v-if="stats_headers.tender_url.amount"><span class="stats_last">{{ stats_headers.tender_url.last }}</span>( {{ stats_headers.tender_url.amount }} )</span>
                  </div>
                </template>
                <el-table
                  :data="stats.tender_url"
                  :show-header="false"
                  size="mini"
                  style="margin-left: 20px;"
                >
                  <el-table-column
                    prop="label"
                    width="220"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="time_event"
                    width="150"
                  >
                    <template slot-scope="scope">
                      <i class="el-icon-time" style="color: rgb(144, 147, 153);"></i>
                      <span style="margin-left: 10px">{{ scope.row.time_event }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="120"
                  >
                    <template slot-scope="scope">
                      <el-button
                        @click.native.prevent="gotoTender(scope.row.target_id)"
                        type="text"
                        size="small"
                      >
                        №{{ scope.row.target_id }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  <div class="stats_header">
                    <span class="stats_title">Просмотры тендеров</span>
                    <span class="stats_amount" v-if="stats_headers.tender_view.amount"><span class="stats_last">{{ stats_headers.tender_view.last }}</span>( {{ stats_headers.tender_view.amount }} )</span>
                  </div>
                </template>
                <el-table
                  :data="stats.tender_view"
                  :show-header="false"
                  size="mini"
                  style="margin-left: 20px;"
                >
                  <el-table-column
                    prop="label"
                    width="220"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="time_event"
                    width="150"
                  >
                    <template slot-scope="scope">
                      <i class="el-icon-time" style="color: rgb(144, 147, 153);"></i>
                      <span style="margin-left: 10px">{{ scope.row.time_event }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="120"
                  >
                    <template slot-scope="scope">
                      <el-button
                        @click.native.prevent="gotoTender(scope.row.target_id)"
                        type="text"
                        size="small"
                      >
                        №{{ scope.row.target_id }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>

          </el-col>
        </el-row>
        
      </div>
      
      <div class="dt-subheader hitting">
        <span>Компании</span>
        <div>
          <el-popconfirm
            title="Добавить компанию в карту?"
            confirmButtonText="Да"
            cancelButtonText="Нет"
            hideIcon
            @onConfirm="addCompany"
          >
            <el-button
              slot="reference"
              icon="el-icon-plus"
              circle
              type="primary"
            >
            </el-button>
          </el-popconfirm>
        </div>
      </div>
      
      <div class="dt-twrap">
        <DTable
          :list="form.companies"
          :schema="{
            id: 'id',
            fields: [
              {
                name: 'name',
                label: 'Название',
                type: 'string',
                editable: true,
                placeholder: 'Нет названия',
              },
              {
                name: 'inn',
                label: 'ИНН',
                type: 'string',
                editable: true,
                placeholder: 'Нет ИНН',
              },
            ],
            rowOperations: [
              {
                name: 'edit',
                label: 'Изменить',
                icon: 'el-icon-edit',
                type: 'info',
              },
              {
                name: 'delete',
                label: 'Удалить?',
                icon: 'el-icon-delete',
                type: 'danger',
                confirm: true,
              },
            ],
          }"
          :loading="loading"
          :paginator="false"
          :selector="false"
          @operation="operateCompanies"
        />
      </div>
      
      <div class="dt-subheader hitting">
        <span>Сотрудники</span>
        <div>
          <el-button
            icon="el-icon-plus"
            circle
            type="primary"
            @click="addUser"
          >
          </el-button>
        </div>
      </div>
      
      <div class="dt-twrap">
        <DTable
          :list="form.users"
          :schema="{
            id: 'id',
            fields: [
              {
                name: 'name',
                label: 'Имя',
                type: 'string',
                editable: true,
              },
              {
                name: 'email',
                label: 'E-mail',
                type: 'string',
                editable: true,
              },
            ],
            rowOperations: [
              {
                name: 'edit',
                label: 'Изменить',
                icon: 'el-icon-edit',
                type: 'info',
              },
              {
                name: 'delete',
                label: 'Удалить?',
                icon: 'el-icon-delete',
                type: 'danger',
                confirm: true,
              },
            ],
          }"
          :loading="loading"
          :paginator="false"
          :selector="false"
          @operation="operateUsers"
        />
      </div>

    </div>
  
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import DTable from '@/components/DTable';

const registration_card = [
  { name: 'company', label: 'Компания' },
  { name: 'catalog', label: 'Вид деятельности' },
  { name: 'name', label: 'Заявитель' },
  { name: 'position', label: 'Должность' },
  { name: 'email', label: 'E-mail заявителя' },
  { name: 'phone', label: 'Контактный телефон' },
  { name: 'personnel', label: 'Количество сотрудников для работы в приложении' },
  { name: 'usage', label: 'Текущий инструмент контроля работы по тендерам' },
];

const events = {
  'tender_add_to_towork': 'Добавлен в работу',
  'tender_add_to_favorites': 'Добавлен в избранные',
  'tender_copy_url': 'ЭТП скопирована',
  'tender_goto_url': 'Переход на ЭТП',
  'tender_send_url': 'ЭТП отправлена',
  'tender_view': 'Тендер просмотрен',
  'app_start': 'Приложение запущено',
  'app_login': 'Вход в приложение',
};

export default {

  name: 'CardsView',
  
  components: {
    DTable,
  },

  props: {
    card_id: String,
  },
  
  data() {
    return {
      form: {
        id: 0,
        name: '',
        manager_id: 0,
        manager_p_id: 0,
        trade_su_user_id: '',
        companies: [],
        users: [],
        registration_info: null,
        comment: '',
        comment_author: '',
        comment_update_time: '',
      },
      options: {
        managers: [ { label: 'Не назначен', value: 0 } ],
      },
      stats: {
        app: [],
        tender_view: [],
        tender_url: [],
        tender_add_to_towork: [],
        tender_add_to_favorites: [],
      },
      loading: false,
      registration_card: registration_card,
    }
  },
  
  computed: {
    
    /* **** stats_headers **** */
    stats_headers() {
      const o = {};
      [ 'app', 'tender_view', 'tender_url', 'tender_add_to_towork', 'tender_add_to_favorites' ].forEach(
        (k) => {
          const len = this.stats[k].length;
          o[k] = {
            amount: len,
            last: len ? this.stats[k][0].time_event : '',
          };
        }
      );
      return o;
    },
    
  },
  
  mounted() {
    this.get_card();
  },        

  methods: {

    /* refresh */
    refresh() {
      this.get_card();
    },

    /* gotoTender */
    gotoTender(id) {
      window.open('https://trade.su/moderator/view/' + id.toString(), '_blank');
    },

    /* get_card */
    get_card() {
      this.loading = true;
      this.$request({
        path: 'new/card/' + this.card_id.toString(),
        data: {
            cards_ids: this.$sup.cards_ids,
        },
        call: [ this.get_card_complete, this ],
        cache: false,
      });
    },

    /* get_card_complete */
    get_card_complete(status, data) {
      if(status == 200) {
        this.options.managers.splice(1, this.options.managers.length - 1);
        Array.prototype.push.apply(this.options.managers, data.managers);
        this.form.id = data.card.id;
        this.form.name = data.card.name;
        this.form.manager_id = data.card.manager_id;
        this.form.manager_p_id = data.card.manager_p_id;
        this.form.comment = data.comment.comment;
        this.form.comment_author = data.comment.author_name;
        this.form.comment_time_update = data.comment.time_update;
        if(typeof data.card._data['trade.su'] !== 'undefined' &&
            data.card._data['trade.su']['user_id'] !== 'undefined') {
          this.form.trade_su_user_id = data.card._data['trade.su']['user_id'];
        }
        if(typeof data.card._data['info'] !== 'undefined' &&
            data.card._data['info']['registration'] !== 'undefined') {
          this.form.registration_info = {};
          Object.keys(data.card._data['info']['registration']).forEach(
            (k) => { this.$set(this.form.registration_info, k, data.card._data['info']['registration'][k]); }
          )
        }
        this.form.companies.splice(0, this.form.companies.length);
        Array.prototype.push.apply(this.form.companies, data.card.companies);
        this.form.users.splice(0, this.form.users.length);
        Array.prototype.push.apply(this.form.users, data.card.users);
        this.parseStats(data.stats);
      }
      this.loading = false;
    },
    
    /* parseStats */
    parseStats(stats) {
      [ 'app', 'tender_view', 'tender_url', 'tender_add_to_towork', 'tender_add_to_favorites' ].forEach(
        k => this.stats[k].splice(0, this.stats[k].length)
      );
      stats.forEach(
        (s) => {
          s.time_event = formatDatetime(s.time_event, 'YYYY-MM-DD hh:mm', true, false);
          s.label = events[s.event];
          if(s.event.indexOf('app_') == 0) {
            this.stats.app.push(s);
          }
          else if(s.event == 'tender_view') {
            this.stats.tender_view.push(s);
          }
          else if(s.event == 'tender_add_to_towork') {
            this.stats.tender_add_to_towork.push(s);
          }
          else if(s.event == 'tender_add_to_favorites') {
            this.stats.tender_add_to_favorites.push(s);
          }
          else if([ 'tender_copy_url', 'tender_goto_url', 'tender_send_url' ].indexOf(s.event) > -1) {
            this.stats.tender_url.push(s);
          }
        }
      );
    },
    
    /* updateComment */
    updateComment() {
      this.loading = true;
      const data = {
        card_id: this.form.id,
        comment: this.form.comment,
      };
      this.$request({
        path: '/new/card/comment/update',
        data: data,
        call: [ this.updateCard_complete, this ],
        cache: false,
      });
    },

    /* updateCard */
    updateCard(req) {
      this.loading = true;
      const data = {
        card_id: this.form.id,
        name: this.form.name,
        trade_su_user_id: this.form.trade_su_user_id,
        manager_id: this.form.manager_id,
        manager_p_id: this.form.manager_p_id,
      };
      this.$request({
        path: '/new/card/update',
        data: data,
        call: [ this.updateCard_complete, this ],
        cache: false,
      });
    },

    /* updateCard_complete */
    updateCard_complete(status, data) {
      if(status != 200) {
        if(data._alert) {
          Notification({
            title: data._alert,
            message: '',
            customClass: 'd-update-error',
            //duration: 0,
          });
        }
        this.loading = false;
      }
      else {
        this.refresh();
      }
    },
    
    /* addCompany */
    addCompany() {
      this.loading = true;
      const data = {
        card_id: this.form.id,
        name: 'Новая компания',
        inn: '',
      };
      this.$request({
        path: '/new/card/company/add',
        data: data,
        call: [ this.addCompany_complete, this ],
        cache: false,
      });
    },

    /* addCompany_complete */
    addCompany_complete(status, data) {
      if(status != 200) {
        if(data._alert) {
          Notification({
            title: data._alert,
            message: '',
            customClass: 'd-update-error',
            //duration: 0,
          });
        }
      }
      this.refresh();
    },
    
    /* updateCompany */
    updateCompany(req) {
      this.loading = true;
      const ln = this.form.companies.length;
      for(var i = 0; i < ln; i++) {
        if(this.form.companies[i].id == req.id) {
          this.form.companies[i][req.field] = req.value;
          break;
        }
      }
      const data = {
        company_id: req.id,
      };
      data[req.field] = req.value;
      this.$request({
        path: '/new/card/company/update',
        data: data,
        call: [ this.updateCompany_complete, this ],
        cache: false,
      });
    },

    /* updateCompany_complete */
    updateCompany_complete(status, data) {
      if(status != 200) {
        if(data._alert) {
          Notification({
            title: data._alert,
            message: '',
            customClass: 'd-update-error',
            //duration: 0,
          });
        }
      }
      this.refresh();
    },
    
    /* addUser */
    addUser() {
      this.$router.push({ path: '/digitender/clients/cards/' + this.card_id.toString() + '/users/add' });
    },

    /* updateUser */
    updateUser(req) {
      this.loading = true;
      const ln = this.form.users.length;
      for(var i = 0; i < ln; i++) {
        if(this.form.users[i].id == req.id) {
          this.form.users[i][req.field] = req.value;
          break;
        }
      }
      const data = {
        user_id: req.id,
      };
      data[req.field] = req.value;
      this.$request({
        path: '/new/user/update',
        data: data,
        call: [ this.updateUser_complete, this ],
        cache: false,
      });
    },

    /* updateUser_complete */
    updateUser_complete(status, data) {
      if(status != 200) {
        if(data._alert) {
          Notification({
            title: data._alert,
            message: '',
            customClass: 'd-update-error',
            //duration: 0,
          });
        }
      }
      this.refresh();
    },
    
    /* operateCompanies */
    operateCompanies(data) {
      if(data.operation == 'update') {
        this.updateCompany(data);
      }
      else if(data.operation == 'delete') {
        this.deleteCompany(data.id);
      }
    },
    
    /* operateUsers */
    operateUsers(data) {
      if(data.operation == 'update') {
        this.updateUser(data);
      }
      else if(data.operation == 'delete') {
        this.deleteUser(data.id);
      }
      else if(data.operation == 'edit') {
        this.$router.push({ path: '/digitender/clients/cards/users/view/' + data.id.toString() });
      }
    },

    /* deleteCompany */
    deleteCompany(id) {
      this.loading = true;
      this.$request({
        path: '/new/card/company/delete',
        data: {
            company_id: id,
        },
        call: [ this.deleteCompany_complete, this ],
        cache: false,
      });
    },

    /* deleteCompany_complete */
    deleteCompany_complete(status, data) {
      if(status == 200) {
        this.refresh();
      }
    },

    /* deleteUser */
    deleteUser(id) {
      this.loading = true;
      this.$request({
        path: '/new/user/delete',
        data: {
            user_id: id,
        },
        call: [ this.deleteUser_complete, this ],
        cache: false,
      });
    },

    /* deleteUser_complete */
    deleteUser_complete(status, data) {
      if(status == 200) {
        this.refresh();
      }
    },

  },
  
};


function parseDatetime(s, fromUTC) {
  var d = null;
  const sp = s.split(/[- :]/);
  if(fromUTC) {
    d = new Date(Date.UTC(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0));
  }
  else {
    d = new Date(sp[0], sp[1] - 1, sp[2], sp[3] ? sp[3] : 0, sp[4] ? sp[4] : 0, sp[5] ? sp[5] : 0);
  }
  return d;
}

function formatDatetime(v, f, fromUTC, toUTC) {
  const fm = typeof f !== 'string' ? 'YYYY-MM-DD hh:mm:ss' : f;
  var d = typeof v === 'string' ? d = parseDatetime(v, fromUTC) : v;
  const mask = toUTC ?
    {
      'YYYY': d.getUTCFullYear(),
      'MM': ('0' + (d.getUTCMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getUTCDate()).slice(-2),
      'hh': ('0' + d.getUTCHours()).slice(-2),
      'mm': ('0' + d.getUTCMinutes()).slice(-2),
      'ss': ('0' + d.getUTCSeconds()).slice(-2),
    } :
    {
      'YYYY': d.getFullYear(),
      'MM': ('0' + (d.getMonth() + 1).toString()).slice(-2),
      'DD': ('0' + d.getDate()).slice(-2),
      'hh': ('0' + d.getHours()).slice(-2),
      'mm': ('0' + d.getMinutes()).slice(-2),
      'ss': ('0' + d.getSeconds()).slice(-2),
    };
  const str = fm.replace(/(Y+|M+|D+|h+|m+|s+)/g, (s, g) => mask[g]);
  return str;
}
</script>

<style scope>
.el-notification.d-update-error {
  background-color: #F56C6C;
  border: 0;
}

.d-update-error .el-notification__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.d-update-error .el-notification__title {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.d-update-error .el-notification__closeBtn {
  position: static;
  margin-top: 2px;
  color: rgba(255, 255, 255, .6);
}

.dt-header {
  background-color: rgb(246, 248, 248);
  border-bottom: 1px solid rgb(222, 229, 231);
  padding: 20px;
  height: 80px;
  line-height: 40px;
  font-size: 22px;
  font-weight: 300;
  color: rgb(88, 102, 110);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dt-data {
  padding: 0px 0px 20px 0px;
  margin-bottom: 20px;
}

.dt-p {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dt-form {
  padding: 20px 20px 0px 20px;
  margin-bottom: 2px;
  flex: 1 1 70%;
}

.dt-info {
  padding: 20px 20px 0px 20px;
  margin-bottom: 22px;
  flex: 0 0 320px;
}

.dt-sub {
  font-size: 16px;
  color: rgba(0, 0, 0, .5);
  margin-left: 16px;
}

.dt-subheader {
  background-color: rgb(246, 248, 248);
  border-bottom: 1px solid rgb(222, 229, 231);
  border-top: 1px solid rgb(222, 229, 231);
  padding: 10px 20px 10px 20px;
  height: 60px;
  line-height: 40px;
  font-size: 20px;
  font-weight: 300;
  color: rgb(88, 102, 110);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dt-subheader.hitting {
  position: relative;
  margin-top: -1px;
  z-index: 1;
}

.dt-registration-card .dt-field {
  margin-top: 8px;
}

.dt-registration-card .dt-field:first-child {
  margin-top: 0px;
}

.dt-registration-card .dt-label {
  font-size: 13px;
  font-weight: 300;
  color: rgb(144, 147, 153);
  margin-bottom: 2px;
}

.dt-registration-card .dt-value {
  font-size: 14px;
}

.comment-info {
  font-size: 13px;
  color: rgba(0, 0, 0, .6);
  line-height: 18px !important;
  padding: 4px 0px 0px 0px;
  font-style: italic;
}

.comment-info span {
  color: rgb(82, 147, 205);
  margin-right: 12px;
}

.stats_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.stats_title {
  margin-left: 20px;
}

.stats_amount {
  margin-right: 20px;
  color: rgba(0, 0, 0, .7);
  /*color: rgb(247, 132, 132);*/
}

.stats_last {
  margin-right: 8px;
  font-style: italic;
  font-weight: 400;
  color: rgb(82, 147, 205);
}

.el-collapse {
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.el-collapse-item__content {
  padding-bottom: 20px !important;
}

.el-collapse-item__content .el-table:before {
    width: auto !important;
}

.el-collapse-item__content .el-table__row td {
  color: rgba(0, 0, 0, .8);
}

.el-collapse-item__content .el-table__row:last-child td {
  border-bottom: 0px !important;
}
</style>
